/* eslint-disable react/no-danger */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
/* eslint-disable import/prefer-default-export */

import { FmdGood, NearMe } from "@mui/icons-material";
import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";

const ColorItemLabel = ({ color }) => {
	return (
		<div
			style={{
				height: "3vh",
				width: "3vh",
				borderRadius: "7px",
				backgroundColor: color
			}}
		></div>
	);
};

const createObjectTable = (obj, cell, dictionary, form) => {
	let collection = form?.collectionDefault;
	let attribute = cell?.column?.id;

	if (!collection || !attribute) return null;

	let midPath = collection + "." + attribute;

	let sample = obj[0];
	let sampleKeys = Object.keys(sample);

	return (
		<table>
			<thead>
				<tr>
					{sampleKeys.map((key, index) => {
						let realPath = midPath + "." + key;
						realPath = realPath.split(".")?.join(".items.");
						let fromDictionary = lod_.get(dictionary, realPath);
						if (fromDictionary) {
							return <th key={index}>{fromDictionary.label.fr}</th>;
						} else {
							return null;
						}
					})}
				</tr>
			</thead>
			<tbody>
				{obj.map((row, index) => {
					return (
						<tr key={index}>
							{sampleKeys.map((key, index) => {
								return <td key={index}>{row[key]}</td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const createArrayTable = arr => {
	return <div>{arr.join(", ")}</div>;
};

/**
 * ##############################
 * Display special fields in table
 * e.g : colors, icons, images, etc
 * ##############################
 */
export function handleSpecialCell(cell, rowKey, dictionary, form, list = {}, data = {}) {
	let filtersLabel = [];

	let currentChartCode = list.code;
	let row = data.length > 0 ? data[rowKey] : {};

	let firstKnowledge = null;

	if (!lod_.isEmpty(row)) {
		let knowledges = row.knowledges || [];
		if (knowledges.length > 0) {
			firstKnowledge = knowledges[0];
		}
	}

	switch (cell.column.id) {
		case "reply":
		case "content":
			if (["KNOWLEDGE_PAGINED_LIST", "HISTORY_PAGINED_LIST"].includes(currentChartCode)) {
				return <span dangerouslySetInnerHTML={{ __html: cell.value }}></span>;
			}
			return cell.render("Cell");
		/* Testing page */
		case "firstScore":
			if (currentChartCode === "TESTING_PAGINED_LIST" && firstKnowledge) {
				return <div>{firstKnowledge.score.toFixed(2)}</div>;
			}
			return cell.render("Cell");
		case "firstContent":
			if (currentChartCode === "TESTING_PAGINED_LIST" && firstKnowledge) {
				return <div dangerouslySetInnerHTML={{ __html: firstKnowledge.content }}></div>;
			}
			return cell.render("Cell");
		/* Default pages */
		case "filters":
			if (!cell.value || !lod_.isArray(cell.value)) return null;
			cell.value.map((filter, index) => {
				let realPath = filter.dictionaryPath?.split(".")?.join(".items.");
				let fromDictionary = lod_.get(dictionary, realPath);
				if (fromDictionary && fromDictionary.analytics && fromDictionary.active) {
					filtersLabel.push(fromDictionary.label.fr);
				}
			});
			return <div>{filtersLabel.join(" / ")}</div>;
		case "color":
			return <ColorItemLabel color={cell.value} />;
		case "display.pages":
			return <div>{cell.value.join(", ")}</div>;
		case "images":
			if (!cell.value) return null;
			return <div>voir (bientot dispo)</div>;
		case "createdDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "rawText":
		case "summary":
		case "active":
		case "tested":
		case "analysed":
		case "extracted":
			if (!cell.value) {
				return (
					<Icon color="error" fontSize="medium">
						close
					</Icon>
				);
			} else {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			}
		case "createdAt":
			return (
				<div>
					{new Date(cell.value).toLocaleDateString("fr-FR", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric"
					})}
				</div>
			);
		case "url":
			if (cell.value) {
				return (
					<MDBox component="a" href={cell.value} target="_blank">
						<Tooltip placement="top" title={i18n.t("SETTINGS.see")}>
							<Icon fontSize="medium">visibility</Icon>
						</Tooltip>
					</MDBox>
				);
			} else {
				return null;
			}
		case "edited":
			if (cell.value) {
				return (
					<Icon color="error" fontSize="medium">
						close
					</Icon>
				);
			} else {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			}
		case "feedback":
			if (cell.value === "positive") {
				return (
					<Icon color="success" fontSize="medium">
						thumb_up
					</Icon>
				);
			} else if (cell.value === "negative") {
				return (
					<Icon color="error" fontSize="medium">
						thumb_down
					</Icon>
				);
			} else {
				return cell.render("Cell");
			}
		case "type":
			switch (cell.value) {
				case "file":
					return (
						<Tooltip placement="top" title="Fichier">
							<Icon fontSize="medium">insert_drive_file</Icon>
						</Tooltip>
					);
				case "csv":
					return (
						<Tooltip placement="top" title="CSV">
							<Icon fontSize="medium">table_chart</Icon>
						</Tooltip>
					);
				case "BDD":
					return (
						<Tooltip placement="top" title="BDD">
							<Icon fontSize="medium">database</Icon>
						</Tooltip>
					);
				case "website":
					return (
						<Tooltip placement="top" title="Site Web">
							<Icon fontSize="medium">language</Icon>
						</Tooltip>
					);
				default:
					return cell.render("Cell");
			}
		default:
			if (lod_.isArray(cell.value)) {
				let sample = cell.value[0];

				if (lod_.isObject(sample)) {
					return createObjectTable(cell.value, cell, dictionary, form);
				} else {
					return createArrayTable(cell.value);
				}
			} else {
				return cell.render("Cell");
			}
	}
}
