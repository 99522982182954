/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, IconButton, InputAdornment, Switch } from "@mui/material";
import FaiqActions from "redux-react/actions/faiqActions";
import ChartsActions from "redux-react/actions/chartsActions";
import MDInput from "components/Basics/MDInput";
import Message from "./components/Message";
import { v4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";

/**
 * Main component
 */
export default function ChatPage({ route }) {
	const inputRef = useRef(null);
	const endRef = useRef(null);
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();

	const [pageFilters, setPageFilters] = useState([]);
	const [messages, setMessages] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [threadID, setThreadID] = useState(null);

	const [temporalMessage, setTemporalMessage] = useState("");

	const [parametersOutputStep, setParametersOutputStep] = useState("chunk");

	const scrollToBottom = () => {
		endRef.current.scrollIntoView({ behavior: "smooth" });
	};

	// Buffer to store JSON data when message are incomplete
	const jsonBufferRef = useRef("");

	const sendMessage = async () => {
		// Reset buffer
		jsonBufferRef.current = "";

		let buildedFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);

		let metadatas = {};

		Object.entries(buildedFilters).forEach(([key, value]) => {
			metadatas[key] = value.value;
		});

		if (!inputValue.trim()) return;
		setTemporalMessage("");

		setMessages(prevList => [...prevList, { message: inputValue, direction: "out" }]);
		setInputValue("");
		let randomID = Math.floor(Math.random() * 1000000);

		setTimeout(() => {
			setMessages(prevList => [
				...prevList,
				{ message: ". . .", direction: "in", loading: true, id: randomID }
			]);
		}, 100);

		// Create future message
		let chunkedMessageID = Math.floor(Math.random() * 1000000);
		let newMessage = {
			message: temporalMessage,
			direction: "in",
			sources: [],
			id: chunkedMessageID
		};

		dispatch(
			FaiqActions.tryFAIQ(
				profile.assistantID,
				inputValue,
				threadID,
				metadatas,
				{
					parameters: {
						outputStep: parametersOutputStep
					}
				},
				// Before stream start
				() => {
					setMessages(prevList => prevList.filter(item => item.id !== randomID));
					setMessages(prevList => [...prevList, newMessage]);
				},
				// While reveiving data
				({ chunk }) => {
					chunk = jsonBufferRef.current + chunk;

					// Regex to test if we have an end message
					const regexEnd = /^\{"type":"end".*\}$/;
					// Regex to test if we have an error
					const regexError = /^\{"type":"error".*\}$/;
					// Regex to test if we have an message in the chunk
					// const regexMessage = /.*\{"type":"message".*\}$/;

					switch (true) {
						case regexError.test(chunk): {
							// We have an end message
							let parsedChunk = JSON.parse(chunk);

							setTemporalMessage(prevMessage => {
								setMessages(prevList => [
									...prevList.filter(item => item.id !== chunkedMessageID),
									{
										message: "Le service est momentanément indisponible",
										error: true,
										direction: "in",
										sources: []
									}
								]);

								return prevMessage;
							});
							break;
						}
						case regexEnd.test(chunk): {
							// We have an end message
							let parsedChunk = JSON.parse(chunk);

							setTemporalMessage(prevMessage => {
								if (prevMessage.trim()) {
									setMessages(prevList => [
										...prevList.filter(item => item.id !== chunkedMessageID),
										{ ...newMessage, message: prevMessage, sources: parsedChunk?.payload?.sources }
									]);
								}

								return prevMessage;
							});
							break;
						}
						// Case where we're sending X messages from the back. Used to display
						// knowledge content with no LLM
						case chunk.startsWith(`{"type":"message`): {
							// Get all messages in the chunk
							let chunked = chunk.split(/(?=\{"type":"message".*\})/);
							for (let message of chunked) {
								// Check for end
								const regexEndInChunk = /.*\{"type":"end".*\}$/;

								if (regexEndInChunk.test(message)) {
									let chunkedEnd = message.split(/(?=\{"type":"end".*\})/);
									if (chunkedEnd.length === 2) {
										message = chunkedEnd[0];
									}
								}

								try {
									// We try to parse the message,
									// if it's a valid JSON, we update the message
									let parsedMessage = JSON.parse(message);

									setMessages(prevList => [
										...prevList.filter(item => item.id !== chunkedMessageID),
										{
											id: v4(),
											direction: "in",
											message: parsedMessage?.payload?.content,
											sources: [parsedMessage?.payload?.source]
										}
									]);
								} catch {
									// If we can't parse the message, we update the buffer
									// And it will be used in the next chunk
									jsonBufferRef.current = message;
								}
							}
							break;
						}
						default: {
							// We have a new chunk

							// Check if we have the end regex in the chunk
							// if we have, split the chunk and update the message
							// if we don't have, update the message

							const regexEndInChunk = /.*\{"type":"end".*\}$/;
							let sources = [];

							if (regexEndInChunk.test(chunk)) {
								let chunked = chunk.split(/(?=\{"type":"end".*\})/);
								if (chunked.length === 2) {
									chunk = chunked[0];
									try {
										let chunkEnd = JSON.parse(chunked[1]);
										sources = chunkEnd?.payload?.sources;
									} catch {
										// Do nothing
									}
								}
							}

							setTemporalMessage(prevMessage => {
								let update = prevMessage + chunk;

								setMessages(prevList => [
									...prevList.filter(item => item.id !== chunkedMessageID),
									{ ...newMessage, message: update, sources }
								]);

								return update;
							});
							break;
						}
					}
				},
				// Last event of stream (when close)
				() => {},
				// After stream end
				() => {},
				err => {
					setMessages(prevList => prevList.filter(item => item.id !== randomID));
					setMessages(prevList => [
						...prevList,
						{
							message: "Le service est momentanément indisponible",
							error: true,
							direction: "in",
							sources: []
						}
					]);
				}
			)
		);
	};

	const resetChat = () => {
		setMessages([]);
		setThreadID(v4());
	};

	// Scroll to bottom when message list change
	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		// Set thread ID on component mount
		resetChat();
	}, [profile.assistantID]);

	/* Get filters from back */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, route, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		async function load() {
			// Set page filters
			let pageFilters = await getPageFilters();
			setPageFilters(pageFilters);
		}

		load();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout flex>
			<DashboardNavbar
				filters={[
					<MDBox
						display="flex"
						justifyContent="space-between"
						style={{
							width: "100%"
						}}
					>
						{/* First container */}
						<MDBox display="flex">
							<MDBox sx={{ mr: 1 }}>
								<MDTypography variant="caption">{t("CONFIG.chunk")}</MDTypography>
								<Switch
									checked={parametersOutputStep === "llm"}
									onChange={e => {
										setParametersOutputStep(e.target.checked ? "llm" : "chunk");
									}}
								/>
								<MDTypography variant="caption">{t("CONFIG.answer")}</MDTypography>
							</MDBox>
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
						</MDBox>
						{/* Second container */}
						<MDBox display="flex" flexDirection="row"></MDBox>
					</MDBox>
				]}
			/>
			{/* Input */}
			<MDBox
				sx={{ mt: 2, mb: 1 }}
				style={{
					zIndex: 1000,
					boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.1)"
				}}
				bgColor="white"
				borderRadius="xl"
			>
				<MDInput
					ref={inputRef}
					sx={{ p: 1 }}
					variant="standard"
					fullWidth
					placeholder="Message à FAIQ"
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					onKeyPress={e => {
						if (e.key === "Enter") {
							sendMessage();
						}
					}}
					InputProps={{
						disableUnderline: true,
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => resetChat()}>
									<Icon>replay</Icon>
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</MDBox>
			{/* Messages list */}
			<MDBox
				sx={{ p: 1 }}
				flex="1"
				style={{
					height: "100%",
					overflowY: "auto"
				}}
				display="flex"
				flexDirection="column"
			>
				{messages.map((message, index) => {
					let mustBeOpen = true;
					let previousIndex = index - 1;
					if (previousIndex >= 0 && message.direction === "in") {
						let previousMessage = messages[previousIndex];
						if (previousMessage.direction === "in") {
							mustBeOpen = false;
						}
					}

					return (
						<Message
							key={index}
							direction={message.direction}
							sources={(message.sources ?? []).filter(source => source?.metadatas?.url)}
							message={message.message}
							loading={message.loading}
							open={mustBeOpen}
						/>
					);
				})}
				<div ref={endRef}></div>
			</MDBox>
		</DashboardLayout>
	);
}
