/* eslint-disable no-unreachable */
import MDBox from "components/Basics/MDBox";
import { Card, CardContent, Icon } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useSelector, useDispatch } from "react-redux";
import FaiqActions from "redux-react/actions/faiqActions";
import MDInput from "components/Basics/MDInput";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";

const IntegrationPart = ({ copySetting, setCopySetting, settings, setSettings }) => {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const hideApiKey = apiKey => {
		return `${apiKey.substring(0, 5)}XXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX`;
	};

	const refreshApiKey = () => {
		dispatch(
			FaiqActions.refreshFaiqApiKey(profile.assistantID, res => {
				setSettings({
					...settings,
					apiKey: res.apiKey
				});

				setCopySetting({
					...copySetting,
					apiKey: res.apiKey
				});
			})
		);
	};

	return (
		<>
			<MDBox mt={3} mb={3}>
				<MDTypography variant="h3">{i18n.t("CONFIG.integration")}</MDTypography>
			</MDBox>

			<MDBox mt={3}>
				<Card>
					<CardContent>
						{/* API Key */}
						<MDBox mt={2}>
							<MDTypography variant="h6">{i18n.t("CONFIG.apiKey")}</MDTypography>
							<MDBox display="flex" alignItems="stretch">
								<MDButton
									color="info"
									variant="gradient"
									sx={{ mr: 1 }}
									onClick={() => {
										navigator.clipboard.writeText(settings.apiKey);
										dispatch(
											display({
												type: "info",
												message: i18n.t("CONFIG.DISPLAY.copiedApiKey")
											})
										);
									}}
								>
									<Icon>copy</Icon>&nbsp;{i18n.t("SETTINGS.copy")}
								</MDButton>

								<MDInput
									disabled
									className="dialogInput"
									value={hideApiKey(settings.apiKey)}
								></MDInput>

								<MDButton color="info" variant="gradient" sx={{ ml: 1 }} onClick={refreshApiKey}>
									<Icon>replay</Icon>&nbsp;{i18n.t("CONFIG.regenerate")}
								</MDButton>
							</MDBox>
						</MDBox>
						{/* Wordpress */}
						<MDBox mt={3}>
							<MDTypography variant="h6">{i18n.t("CONFIG.wordpress")}</MDTypography>
							<MDBox display="flex" alignItems="center" justifyContent="space-between">
								<MDTypography variant="body2">
									{i18n.t("CONFIG.downloadWordpressPlugin")}
								</MDTypography>

								<MDButton
									color="info"
									variant="gradient"
									sx={{ ml: 1 }}
									onClick={() => {
										window.open(settings.wordpressPlugin, "_blank");
									}}
									disabled={!settings.wordpressPlugin}
								>
									<Icon>download</Icon>&nbsp;{i18n.t("CONFIG.download")}
								</MDButton>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>
			</MDBox>
		</>
	);
};

export default IntegrationPart;
