/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip
} from "@mui/material";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import i18n from "i18n";
import { display } from "redux-react/reducers/snackBarReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import { useSearchParams } from "react-router-dom";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";

const Message = ({ message }) => {
	let styleDirection = {};

	let { direction, text } = message;

	if (direction === "in") {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: "white",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	return (
		<MDBox mt={1} style={styleDirection}>
			<p
				style={{
					fontSize: 12
					// whiteSpace: "pre-wrap"
				}}
				dangerouslySetInnerHTML={{ __html: text }}
			></p>
		</MDBox>
	);
};

const ConversationBox = ({ conversation }) => {
	const dispatch = useDispatch();
	const { profile } = useSelector(state => state);

	const [questionDetails, setQuestionDetails] = useState(null);

	const [openKnowledgesDialog, setOpenKnowledgesDialog] = useState(false);

	useEffect(() => {
		setQuestionDetails(null);
	}, [conversation]);

	return (
		<MDBox
			style={{
				height: "95%"
			}}
			display="flex"
			flexDirection="column"
		>
			<MDBox
				p={1}
				flex="1"
				borderRadius="md"
				style={{
					position: "relative",
					backgroundColor: "rgb(236 236 236)",
					overflowY: "auto",
					height: "100%"
				}}
			>
				{conversation.messages.map((message, index) => (
					<MDBox
						key={index}
						className="exchangeMessageThread"
						onClick={() => {
							dispatch(
								FaiqActions.getQuestionDetailsByQuestionId(
									profile.assistantID,
									message.questionID,
									res => {
										setQuestionDetails(res);
									}
								)
							);
						}}
					>
						<Tooltip placement="left" title="Voir plus d'informations sur cet échange">
							<Message message={{ text: message.question, direction: "out" }} />
							<Message message={{ text: message.reply, direction: "in" }} />
						</Tooltip>
					</MDBox>
				))}
			</MDBox>
			{questionDetails && (
				<MDBox flex="1" mt={1}>
					{/* Header */}
					<MDBox
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<MDTypography variant="h6">Détails</MDTypography>
						<IconButton onClick={() => setQuestionDetails(null)}>
							<Icon>close</Icon>
						</IconButton>
					</MDBox>
					{/* Content */}
					<MDBox>
						{/* Langage */}
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="body2" fontWeight="bold">
								Langage détécté :
							</MDTypography>
							<MDTypography variant="body2">{questionDetails.questionLanguage}</MDTypography>
						</MDBox>
						{/* Temps de réponse */}
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="body2" fontWeight="bold">
								Temps de réponse :
							</MDTypography>
							<MDTypography variant="body2">{`${questionDetails.replyDuration.toFixed(
								1
							)} secondes`}</MDTypography>
						</MDBox>
						{/* Contexte vide */}
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="body2" fontWeight="bold">
								Contexte vide :
							</MDTypography>
							<MDTypography variant="body2">
								{questionDetails.isEmptyContextReply ? "Oui" : "Non"}
							</MDTypography>
						</MDBox>
						{/* Aucune infos */}
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="body2" fontWeight="bold">
								{`Pas d'informations :`}
							</MDTypography>
							<MDTypography variant="body2">
								{questionDetails.isIDontKnowReply ? "Oui" : "Non"}
							</MDTypography>
						</MDBox>
						{/* Connaissances trouvées */}
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="body2" fontWeight="bold">
								Connaissances trouvées :
							</MDTypography>
							<MDTypography variant="body2">{questionDetails.knowledges.length}</MDTypography>
							<MDButton variant="text" color="info" onClick={() => setOpenKnowledgesDialog(true)}>
								Voir
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
			)}
			{questionDetails && (
				<Dialog
					open={openKnowledgesDialog}
					onClose={() => setOpenKnowledgesDialog(false)}
					fullWidth
					maxWidth="xxl"
				>
					<DialogTitle id="alert-dialog-title">Connaissances trouvées</DialogTitle>
					<DialogContent>
						<TableContainer sx={{ height: "100%" }}>
							<Table>
								<MDBox component="tr" mb={1.5}>
									<MDTypography variant="h6" component="th">
										UUID
									</MDTypography>
									<MDTypography variant="h6" component="th">
										Content
									</MDTypography>
									<MDTypography variant="h6" component="th">
										metadatas
									</MDTypography>
								</MDBox>

								<TableBody>
									{questionDetails.knowledges.map((knowledge, index) => {
										return (
											<TableRow key={index}>
												<TableCell>{knowledge.uuid}</TableCell>
												<TableCell>{knowledge.content}</TableCell>
												<TableCell>
													<Table>
														{knowledge.metadatas &&
															Object.keys(knowledge.metadatas).map((key, index) => {
																let value = knowledge.metadatas[key];
																return (
																	<TableRow key={index}>
																		<td
																			style={{
																				fontWeight: "bold"
																			}}
																		>
																			{key}
																		</td>
																		<td>{value}</td>
																	</TableRow>
																);
															})}
													</Table>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</DialogContent>
					<DialogActions>
						<MDButton
							variant="contained"
							color="info"
							onClick={() => setOpenKnowledgesDialog(false)}
						>
							Fermer
						</MDButton>
					</DialogActions>
				</Dialog>
			)}
		</MDBox>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function HistoryPage({ route }) {
	const [searchParams] = useSearchParams();
	// Possible search params in URL
	const knowledge = searchParams.get("knowledge");

	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Charts states
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);
	// Load charts
	const [reloadTable, setReloadTable] = useState(false);

	const rightPannelContainer = useRef(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [currentConversation, setCurrentConversation] = useState(null);

	const closePannel = () => {
		setOpenPannel(false);
		setCurrentConversation(null);
	};

	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	// Build charts
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
			setReloadTable(!reloadTable);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);

		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}
	// Pin / Unpin question
	function pinnedHistory(questionID, pinned) {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: pinned
						? i18n.t("HISTORY.PINNED.pinStatus")
						: i18n.t("HISTORY.PINNED.unpinStatus")
				})
			);
			setReloadTable(!reloadTable);
		};

		dispatch(
			FaiqActions.pinnedHistory(
				profile.assistantID,
				{
					questionID,
					pinned
				},
				onSuccess
			)
		);
	}

	function getParamsFilter() {
		let params = {};
		if (knowledge) {
			params["knowledgeIds.uuid"] = knowledge;
		}
		return params;
	}

	const openConversationPannel = ({ values }) => {
		dispatch(
			FaiqActions.getConversationByThreadId(profile.assistantID, values.threadID, res => {
				setCurrentConversation({ threadID: values.threadID, messages: res });
				setOpenPannel(true);
			})
		);
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox display="flex">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
						</MDBox>
					]}
				/>
			</MDBox>

			{paginedList && (
				<MDBox
					className="pageContentContainer"
					style={{
						display: openPannel ? "flex" : "block"
					}}
				>
					<MDBox flex="1">
						<DefaultDataTable
							route={route}
							onRowClick={values => {
								openConversationPannel({ values });
							}}
							isSelectedRow={values => {
								if (!currentConversation) {
									return false;
								}

								return values.threadID === currentConversation?.threadID;
							}}
							form={route.form}
							dictionary={paginedList.dictionary}
							canSearch
							table={paginedList.data}
							display={paginedList.request.attributesDisplay}
							pagination={paginedList.pagination}
							list={paginedList}
							actions={[
								<IconButton
									handleclick={({ values }) => {
										let pinned = values.pinned ?? false;
										pinnedHistory(values.questionID, !pinned);
									}}
								>
									<Tooltip title={i18n.t("HISTORY.favorite")} placement="top">
										<Icon
											customstyle={({ values }, style) => {
												if (values.pinned) {
													return { ...style, color: "#ffcd00" };
												} else {
													return style;
												}
											}}
											fontSize="medium"
										>
											star
										</Icon>
									</Tooltip>
								</IconButton>,
								<IconButton handleclick={openConversationPannel}>
									<Tooltip title="Afficher la conversation" placement="top">
										<Icon fontSize="medium">message</Icon>
									</Tooltip>
								</IconButton>
							]}
							reloadTable={reloadTable}
							filters={{
								...getLocalStorageBackValues(
									profile.selectedAssistant.assistantID,
									route.route,
									filters
								),
								...getParamsFilter()
							}}
						/>
					</MDBox>
					{openPannel && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<MDBox
								className="rightPannel"
								sx={{ ml: 2 }}
								style={{
									maxWidth: "30vw"
								}}
							>
								<Card sx={{ p: 2, height: "100%" }}>
									<MDBox display="flex" alignItems="center" justifyContent="space-between">
										<MDBox>Conversation</MDBox>
										<MDBox>
											<Tooltip placement="top" title="Fermer">
												<IconButton onClick={() => closePannel()}>
													<Icon>close</Icon>
												</IconButton>
											</Tooltip>
										</MDBox>
									</MDBox>
									{/* Conversation */}
									{currentConversation && <ConversationBox conversation={currentConversation} />}
								</Card>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			)}

			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
