/* eslint-disable no-unreachable */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/**
 * Main application source
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import { deleteFileUrl } from "helpers/s3";
import AddSourceDialog from "./AddSourceDialog";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import { display } from "redux-react/reducers/snackBarReducer";
import MDTypography from "components/Basics/MDTypography";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { socket } from "redux-react/middleware/ws";
import ProcessSourceDialog from "./ProcessSourceDialog";
import MDBadge from "components/Basics/MDBadge";
import EditMetadatas from "./actions/EditMetadatas";
/**
 * Main component
 */
export default function SourcesPage({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Main chart
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);

	const [reloadTable, setReloadTable] = useState(false);
	// Add new source dialog
	const [addNewSource, setAddNewSource] = useState(false);
	// Process source dialog
	const [processSource, setProcessSource] = useState({ open: false, source: {} });
	// Delete confirm dialog
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	// Confirm extract again
	const [confirmExtractDialog, setConfirmExtractDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [selectedRows, setSelectedRows] = useState([]);
	// Actions menu
	const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

	const [actionEditMetadata, setActionEditMetadata] = useState(null);

	/* Load chart */
	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts(res.charts);
			setReloadTable(!reloadTable);
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/* Build charts */
	function buildRawCharts() {
		if (lod_.isEmpty(rawCharts)) return;
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
		};
		// Build charts
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts,
				getLocalStorageBackValues(profile.selectedAssistant.assistantID, route.route, filters),
				mandatoryFilters,
				onSuccess
			)
		);
	}
	/* Handle add dialog */
	const addSourceHandle = source => {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: i18n.t("SOURCES.UPLOAD.CALLS.success")
				})
			);
			setReloadTable(!reloadTable);
		};
		dispatch(FaiqActions.saveSource(profile.assistantID, source, onSuccess));
	};
	/* Handle edit dialog */
	const editSourceHandle = ({ code, update }) => {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: i18n.t("SOURCES.EDIT.CALLS.success")
				})
			);
			setReloadTable(!reloadTable);
		};
		dispatch(FaiqActions.updateSource(profile.assistantID, code, update, onSuccess));
	};
	/* Handle delete confirm dialog */
	const actionDeleteHandle = values => {
		let fileName = values["file.name"];
		let filePath = values["file.path"];
		let fileCode = values.code;

		const handleConfirmDialog = async () => {
			setConfirmDialog({
				open: false
			});

			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SOURCES.DELETE.CALLS.success"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			// Delete source in database
			dispatch(FaiqActions.deleteSource(profile.assistantID, fileCode, onSuccess));
			// Delete file in s3
			if (fileName && filePath) {
				await deleteFileUrl(fileName, filePath);
			}
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("SOURCES.theSource")} "${fileName}"`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t(
				"SOURCES.theSource"
			)} "${fileName}" ? \n ${i18n.t("SOURCES.DELETE.warnDeleteKnowledges")}`,
			handleConfirm: handleConfirmDialog
		});
	};

	/**
	 * Delete many sources
	 * @param {*} sources - List of sources code to delete
	 */
	const actionDeleteManySources = sources => {
		const handleConfirmDialog = async () => {
			setConfirmDialog({
				open: false
			});

			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SOURCES.DELETE.CALLS.success"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			// Delete source in database
			dispatch(FaiqActions.deleteSources(profile.assistantID, sources, onSuccess));
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("SOURCES.theSources")}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t("SOURCES.theSources")} ?`,
			handleConfirm: handleConfirmDialog
		});
	};

	/* Handle save of edit multiple metadatas sources */
	const handleSaveMetadatas = metadatas => {
		const sourceToEdit = selectedRows.map(row => row.code);

		const onSuccess = ({ updatedCount }) => {
			if (!lod_.isNil(updatedCount)) {
				if (updatedCount > 0) {
					dispatch(
						display({
							type: "success",
							message: `${updatedCount} source(s) ont été modifiée(s)`
						})
					);
				} else {
					dispatch(
						display({
							type: "error",
							message: "Aucune source n'a été modifiée"
						})
					);
				}
			}
			setReloadTable(!reloadTable);
		};

		dispatch(
			FaiqActions.updateSourcesMetadatas(
				profile.assistantID,
				{
					sources: sourceToEdit,
					metadatas
				},
				onSuccess
			)
		);

		setActionsAnchorEl(null);
		setSelectedRows([]);
	};

	/* Start sources process */
	function startProcessSources(sources = []) {
		const onSuccess = res => {
			setReloadTable(!reloadTable);
		};
		dispatch(FaiqActions.processSources(profile.assistantID, sources, onSuccess));

		dispatch(
			display({
				type: "info",
				message: "Processus de génération lancé"
			})
		);
	}

	const allSourcesAreTextExtracted = sources => {
		return sources.every(source => source.rawText);
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	// WS event
	function sourceExtractedResult({ success, message }) {
		let translatedtext = i18n.exists(`WS.MESSAGE.${message}`)
			? i18n.t(`WS.MESSAGE.${message}`)
			: message;

		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext,
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	function wsRefreshList() {
		setReloadTable(prev => !prev);
	}

	function wsProcessSourceEnd({ success, message }) {
		let translatedtext = i18n.exists(`WS.MESSAGE.${message}`)
			? i18n.t(`WS.MESSAGE.${message}`)
			: message;

		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext,
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	useEffect(() => {
		// Manuel process extraction end
		socket.on("source_extracted_result", sourceExtractedResult);

		// Text has been extracted (UND return)
		socket.on("extract_source_text_result", sourceExtractedResult);
		// Summary has been generated
		socket.on("faiq_source_summary_generated_result", wsRefreshList);
		// Knowledges has been generated
		socket.on("faiq_source_create_knowledges_result", wsRefreshList);
		// Automatic extraction has been executed
		socket.on("process_source_result", wsProcessSourceEnd);

		return () => {
			socket.off("source_extracted_result", sourceExtractedResult);
			socket.off("extract_source_text_result", sourceExtractedResult);
			socket.off("faiq_source_summary_generated_result", wsRefreshList);
			socket.off("faiq_source_create_knowledges_result", wsRefreshList);
			socket.off("process_source_result", wsProcessSourceEnd);
		};
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox
						display="flex"
						justifyContent="space-between"
						style={{
							width: "100%"
						}}
					>
						{/* First container */}
						<MDBox display="flex" flex="1">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
							{/* Buttons */}
							<MDBox
								flex="1"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								flexDirection="row"
							>
								{/* Left */}
								<MDBox>
									<MDButton
										style={{ height: "100%", marginRight: "0.75rem" }}
										variant="contained"
										color="info"
										onClick={() => {
											if (!paginedList?.dictionary?.metadatas) {
												dispatch(
													display({
														type: "error",
														message: "Aucune source de donnée n'est disponible"
													})
												);
											} else {
												setAddNewSource(true);
											}
										}}
									>
										<Icon>create</Icon>&nbsp;{i18n.t("SETTINGS.add")}
									</MDButton>
								</MDBox>
								{/* Right */}
								<MDBox>
									<MDBadge color="error" badgeContent={selectedRows.length} circular size="xs">
										<MDButton
											color="info"
											onClick={e => {
												// setAnchorEl(e.currentTarget);
												setActionsAnchorEl(e.currentTarget);
											}}
											disabled={selectedRows.length === 0}
										>
											<Icon>list_alt</Icon>&nbsp;Actions
										</MDButton>

										<Menu
											id="simple-actions-menu"
											anchorEl={actionsAnchorEl}
											open={Boolean(actionsAnchorEl)}
											onClose={e => {
												setActionsAnchorEl(null);
												e.stopPropagation();
												e.preventDefault();
											}}
										>
											{/* Options */}
											<MenuItem disabled style={{ opacity: 1 }}>
												<MDTypography variant="caption">
													{i18n.t(`FAIQ.TESTING.edition`)}
												</MDTypography>
											</MenuItem>
											<MenuItem
												onClick={() => {
													const sources = selectedRows.map(row => row.code);
													actionDeleteManySources(sources);
												}}
												style={{
													color: "red"
												}}
											>
												<Icon fontSize="medium">delete</Icon>&nbsp;Supprimer
											</MenuItem>
											{/*  */}
											<Divider />
											<MenuItem disabled style={{ opacity: 1 }}>
												<MDTypography variant="caption">
													{i18n.t(`FAIQ.TESTING.actions`)}
												</MDTypography>
											</MenuItem>
											<MenuItem
												onClick={() => {
													setActionEditMetadata(true);
												}}
											>
												<Icon fontSize="medium">edit</Icon>&nbsp;Editer les métadonnées
											</MenuItem>

											<MenuItem
												disabled={!allSourcesAreTextExtracted(selectedRows)}
												onClick={() => {
													const sources = selectedRows.map(row => row.code);
													startProcessSources(sources);
													setActionsAnchorEl(null);
													setSelectedRows([]);
												}}
											>
												<Icon fontSize="medium">settings</Icon>&nbsp;Extraction automatique
											</MenuItem>
										</Menu>
									</MDBadge>
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					route={route}
					checkbox
					selectedRows={selectedRows}
					handleSelection={values => {
						setSelectedRows(values);
					}}
					isSelectedRow={row => {
						const existingRow = selectedRows.find(r => r.code === row.code);
						return Boolean(existingRow);
					}}
					dictionary={paginedList.dictionary}
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					pagination={paginedList.pagination}
					list={paginedList}
					optionsAction="left"
					actions={[
						<IconButton
							handleclick={({ values }, e) => {
								setMenuValues(values);
								setMenuRef(e.currentTarget);
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<Icon fontSize="medium">more_vert</Icon>
						</IconButton>
					]}
					reloadTable={reloadTable}
					filters={getLocalStorageBackValues(
						profile.selectedAssistant.assistantID,
						route.route,
						filters
					)}
				/>
			)}
			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				{/* Options */}
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.edition`)}</MDTypography>
				</MenuItem>
				<MenuItem component="a" href={menuValues.url} target="_blank">
					<Icon fontSize="medium">visibility</Icon>&nbsp;{i18n.t("SETTINGS.see")}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setProcessSource({
							open: true,
							source: menuValues
						});

						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
				</MenuItem>
				{/*  */}
				<Divider />
				<MenuItem
					onClick={() => {
						actionDeleteHandle(menuValues);
						setMenuRef(null);
					}}
					style={{
						color: "red"
					}}
				>
					<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
				</MenuItem>
				{/*  */}
				<Divider />
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				<MenuItem
					disabled={menuValues.type === "csv"}
					onClick={() => {
						setMenuRef(null);
						socket.emit("extract_source_text", { code: menuValues.code });
						dispatch(
							display({
								type: "info",
								message: "Texte en cours d'extraction"
							})
						);
					}}
				>
					<Icon fontSize="medium">text_snippet</Icon>&nbsp;Extraire le texte
				</MenuItem>
				<MenuItem
					disabled={!menuValues.rawText}
					onClick={() => {
						setMenuRef(null);
						socket.emit("extract_source_metadatas", { code: menuValues.code });
						dispatch(
							display({
								type: "info",
								message: "Métadonnées en cours d'extraction"
							})
						);
					}}
				>
					<Icon fontSize="medium">list</Icon>&nbsp;Extraire les métadonnées
				</MenuItem>
				<MenuItem
					disabled={menuValues.type === "csv" || !menuValues.rawText}
					onClick={() => {
						setMenuRef(null);
						startProcessSources([menuValues.code]);
					}}
				>
					<Icon fontSize="medium">settings</Icon>&nbsp;Extraction automatique
				</MenuItem>
				{/*  */}
				{/* <Divider /> */}
				{/* <MenuItem
					disabled={menuValues.type === "csv"}
					onClick={() => {
						setProcessSource({
							open: true,
							source: menuValues
						});

						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">construction</Icon>&nbsp;Extraction manuelle
				</MenuItem> */}
			</Menu>
			{paginedList && addNewSource && (
				<AddSourceDialog
					metadatas={paginedList.dictionary.metadatas}
					open={addNewSource}
					handleCloseDialog={async skeleton => {
						let fileName = skeleton?.file?.name;
						let filePath = skeleton?.file?.path;
						setAddNewSource(false);
						// if user cancel upload, delete file in s3
						if (fileName && filePath) {
							await deleteFileUrl(fileName, filePath);
						}
					}}
					handleSave={addSourceHandle}
					dictionary={paginedList.dictionary}
				/>
			)}
			{/* Process source dialog */}
			{paginedList && processSource.open && (
				<ProcessSourceDialog
					open={processSource.open}
					handleCloseDialog={() => {
						setProcessSource({ open: false });
					}}
					handleSave={() => {
						setReloadTable(!reloadTable);
					}}
					metadatasDictionary={paginedList.dictionary.metadatas}
					source={processSource.source}
				/>
			)}
			{/* Dialog to confirm delete */}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
			{/* Dialog to confirm another extract */}
			<ConfirmDialog
				open={confirmExtractDialog.open}
				title={confirmExtractDialog.title}
				content={confirmExtractDialog.content}
				handleClose={() => {
					setConfirmExtractDialog({
						open: false
					});
				}}
				handleConfirm={confirmExtractDialog.handleConfirm}
			/>

			{/* Actions */}
			{paginedList && (
				<EditMetadatas
					open={Boolean(actionEditMetadata)}
					handleCloseDialog={() => {
						setActionEditMetadata(null);
					}}
					selectedRows={selectedRows}
					handleSave={handleSaveMetadatas}
					metadatasDictionary={paginedList.dictionary.metadatas}
				/>
			)}
			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
