/* eslint-disable prefer-destructuring */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import OneValueChart from "pages/charts/oneValueChart";
import ProportionChart from "pages/charts/proportionChart";
import CrossTable from "pages/charts/crossTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import { setFilter } from "redux-react/reducers/filtersReducers";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { getInterval } from "components/Custom/DatePresets";

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function StatisticPage({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();

	const [pageFilters, setPageFilters] = useState([]);
	const [charts, setCharts] = useState([]);

	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);
	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.customType || chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					route.system ?? false,
					res => resolve(res.charts)
				)
			);
		});
	}
	/* Get filters from back */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, route, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/* Build default filters */
	function buildDefaultFilters(defaultFilters) {
		let returnObject = {};

		for (let filter of defaultFilters) {
			// If filter has no default value, continue
			if (!filter.defaultValue) {
				continue;
			}
			// Set default value by type
			switch (filter.type) {
				// Datetime
				case "datetime": {
					// Get value & interval
					let defaultValue = filter.defaultValue;
					// Set datetime filter to store, to display it on the page
					dispatch(
						setFilter({
							assistantID: profile.assistantID,
							page: route.route,
							attribute: filter.attribute,
							type: "datetime",
							mode: "auto",
							code: defaultValue
						})
					);

					let interval = getInterval(defaultValue);
					// Mapped value
					returnObject[filter.attribute] = {
						name: filter.attribute,
						type: filter.type,
						value: interval
					};
					break;
				}
				default:
					break;
			}
		}
		return returnObject;
	}
	/* Load charts from back */
	async function loadCharts(defaultFilters = null) {
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// If there is default filters, set them (ONLY ON PAGE LOAD)
		if (!lod_.isEmpty(defaultFilters)) {
			Object.keys(actualFilters).map(key => {
				let filter = actualFilters[key];
				let defaultFilter = defaultFilters[key];
				// Replace value if it is empty
				if (lod_.isEmpty(filter?.values)) {
					actualFilters[key] = {
						...defaultFilter
					};
				}
			});
		}
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		// setChartsLoading(false);
	}
	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setFirstLoad(true);
		// setChartsLoading(true);

		async function load() {
			// Set page filters
			let pageFilters = await getPageFilters();
			setPageFilters(pageFilters);
			// Load the charts with default filters
			let defaultFilters = buildDefaultFilters(pageFilters);
			loadCharts(defaultFilters);
			// Avoid multiples requests
			setFirstLoad(false);
		}

		load();
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * When filters change, reload charts
	 */
	useEffect(() => {
		if (!firstLoad) {
			loadCharts();
		}
	}, [filters]);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
					</MDBox>
				]}
			/>
			<MDBox mt={5}>
				<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
					{getChartsData(["oneValue"]).map((chart, index) => {
						/*  Get the first data object, because it has to be the only one */
						return (
							<OneValueChart
								id={chart.code}
								key={index}
								chart={chart}
								data={chart.data}
								xs={2}
								md={2}
								lg={2}
							/>
						);
					})}
				</Grid>
				{/* Proportion graphs */}
				<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
					{getChartsData(["proportion", "oneDimension"]).map((chart, index) => {
						return (
							<ProportionChart
								id={chart.code}
								key={index}
								chart={chart}
								data={chart.data}
								mt={5}
								xs={2}
								md={4}
								lg={2}
								xxl={3}
							/>
						);
					})}
				</Grid>
				{/* Cross Tables */}
				<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
					{getChartsData(["crossTable", "timeSeries", "multiCollection"]).map((chart, index) => {
						return (
							<CrossTable
								id={chart.code}
								chart={chart}
								data={chart.data}
								key={index}
								mt={5}
								xs={2}
								md={4}
								lg={2}
								xxl={6}
							/>
						);
					})}
				</Grid>
			</MDBox>

			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
