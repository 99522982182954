/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
/**
 * Main application for forms
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lod_ from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { read, utils } from "xlsx";
import { getLocalStorageBackValues } from "components/Custom/Filters/filters";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import ImportDialog from "./import";

import { display } from "redux-react/reducers/snackBarReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
/**
 *
 * @param {Object} route
 *  - route : actual route
 * @returns
 */
export default function FormsPage({ route }) {
	const dispatch = useDispatch();
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// Filters configuration for the page
	const [pageFilters, setPageFilters] = useState([]);
	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);

	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");

	const [selectedId, setSelectedId] = useState(null);
	const [reloadTable, setReloadTable] = useState(false);
	// Datas for dialog
	const [importDialog, setImportDialog] = useState({ open: false });
	// Dictionary for dialog
	const [dictionary, setDictionary] = useState({});
	// File name when import CSV
	const [fileImportName, setFileImportName] = useState("");

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.customType || chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters, pageFilters) {
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					mandatoryFilters,
					route.system ?? false,
					res => resolve(res.charts)
				)
			);
		});
	}
	/**
	 * Get filters from back
	 */
	async function getPageFilters() {
		if (route.filter) {
			// If route has filter, get it
			return new Promise((resolve, reject) => {
				dispatch(
					ChartsActions.getPageFilters(profile.assistantID, route.filter, route, res => {
						resolve(res.filters);
					})
				);
			});
		} else {
			// return empty array
			return [];
		}
	}
	/**
	 * Load charts from back
	 */
	async function loadCharts() {
		// Get all filters from back
		let pageFilters = await getPageFilters();
		setPageFilters(pageFilters);
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters, pageFilters);
		setCharts(chartsFromDatabase);
		setDictionary(chartsFromDatabase.charts[0].dictionary);
		setChartsLoading(false);
	}
	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setChartsLoading(true);
		setFirstLoad(false);
		loadCharts();
	}, [profile.selectedAssistant.assistantID, route]);

	const actionEditHandle = (items, target) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(false);
			setSelectedId(items._id);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
		};
		dispatch(FormActions.getItemByID(items._id, target, route.form.routeDictionary, onSuccess));
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteItem = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, {}, onSuccess));
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateItem = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique };
		if (empty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};
	// Handle import CSV
	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();

			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				// Open import modal with data
				setImportDialog({ ...importDialog, open: true, values: data });
			};
			reader.readAsArrayBuffer(file);
			// Set file name
			setFileImportName(file.name);
			// Reset input
			event.target.value = null;
		} catch (error) {}
	};
	// Import CSV datas in DB
	const handleImportCSV = obj => {
		// Add supplementary datas
		obj.collection = route.form.collectionDefault;
		obj.assistantID = profile.assistantID;
		obj.fileName = fileImportName;

		const onSuccess = res => {
			dispatch(
				display({
					message: `${res.insertedCount} données importées`,
					type: "success"
				})
			);

			setReloadTable(!reloadTable);
		};

		dispatch(FormActions.importCSV(obj, onSuccess));
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
		return (
			<DashboardLayout>
				{!lod_.isEmpty(dictionary) && (
					<ImportDialog
						{...importDialog}
						dictionary={lod_.get(dictionary, route.form.routeDictionary)}
						handleClose={() => setImportDialog({ ...importDialog, open: false })}
						handleSave={handleImportCSV}
					/>
				)}

				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<>
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={actionEditEmptyHandle}
								>
									<Icon>add</Icon>&nbsp;
									{i18n.t("FORMS.LABELS.add") + (route?.form?.pageLabel ?? route.name)}
								</MDButton>
								<>
									{route.form.importCSV === true && (
										<MDButton
											style={{ height: "100%" }}
											variant="contained"
											component="label"
											color="info"
										>
											<input
												type="file"
												name="file"
												accept={[
													".xls",
													".xlsx",
													".xlsm",
													".xlsb",
													".xlt",
													".xltx",
													".xltm",
													".xlam",
													".ods",
													".csv"
												]}
												hidden
												onChange={handleImportFile}
											></input>
											<Icon>publish</Icon>&nbsp;Importer un fichier
										</MDButton>
									)}
								</>
							</>
						]}
					/>
				</MDBox>
				{addNewLabel && (
					<FormDictionaryDialog
						open={addNewLabel}
						route={route}
						handleCloseDialog={() => setAddNewLabel(false)}
						handleSave={(values, unique, callback, rolesProfileUser) =>
							actionSaveOrCreateItem(values, unique, callback, rolesProfileUser)
						}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess={PI}
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
					/>
				)}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.LABELS.confirmDelete")}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteItem} color="dark" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
				{/*
				 * Pagined table
				 */}
				{getChartsData(["paginedList"]).map((chart, index) => {
					return (
						<DefaultDataTable
							route={route}
							id={chart.code}
							filters={getLocalStorageBackValues(
								profile.selectedAssistant.assistantID,
								route.route,
								filters
							)}
							reloadTable={reloadTable}
							dictionary={chart.dictionary}
							form={route.form}
							list={chart}
							pagination={chart.pagination}
							canSearch
							key={index}
							table={chart.data}
							display={chart.request.attributesDisplay}
							actionEditHandle={items => actionEditHandle(items, chart.request.collection)}
							actionDeleteHandle={items => actionDeleteHandle(items, chart.request.collection)}
						/>
					);
				})}
			</DashboardLayout>
		);
}
